import React, { MutableRefObject, ReactElement } from 'react'
import {
    BundesligaHubProps,
    ComponentType,
    LabelProps,
    LayoutComponentProps,
    TagProps,
} from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import dynamic from 'next/dynamic'
import { prepareTeasers } from '../teasersHelper'
import { getTag } from './utils'
import AdSlot from '@/components/Ads/AdSlot'
import { TEASER_CARD_MAX_ITEMS } from '@/utils/content/constants'
import { OddsDisclaimerContextProvider } from '@/utils/oddsDisclaimer/OddsDisclaimerProvider'
import { prepareForTeaserTracking } from '@/utils/tracking/prepareForTeaserTracking'
import {
    getAdditionalTrackingData,
    insertSelectedFilter,
} from '@/components/BundesligaHub/trackingUtils'
import { BreakPointProps } from '@/helpers/breakpoint'
import AuthorInfo from '@/components/AuthorInfo'
import { EventCardHeadlineProps } from '@/components/UpcomingEventStreams'
import { disabledWidgets } from '@/components/Widget'
import CompetitionStandings from '@/components/CompetitionStandings'
import Apester from '@/components/Apester'

const AsyncComponent = dynamic(() => import('@/components/AsyncComponent'))
const AuthorOverview = dynamic(() => import('@/components/AuthorOverview'))
const BundesligaHub = dynamic(() => import('@/components/BundesligaHub'))
const ContentShowcase = dynamic(() => import('@/components/ContentShowcase'))
const DaznProgramHighlights = dynamic(() => import('@/components/DaznProgramHighlights'))
const Headline = dynamic(() => import('@/components/Headline'))
const Inplayer = dynamic(() => import('@/components/Paywall/Inplayer'), {
    ssr: false,
})
const PodcastSeriesList = dynamic(() => import('@/components/PodcastSeriesList'))
const MatchElementsCard = dynamic(() => import('@/components/MatchElementsCard'))
const NewsFeedArticle = dynamic(() => import('@/components/NewsFeedArticle'))
const NewsFeedCard = dynamic(() => import('@/components/NewsFeedCard'))
const OptaWidget = dynamic(() => import('@/components/OptaWidget'))
const PianoPasswordReset = dynamic(
    () => import('@/components/ThirdParty/Piano/Composer/PasswordReset')
)
const ResultTeaser = dynamic(() => import('@/components/ResultTeaser'))
const StandingsCard = dynamic(() => import('@/components/StandingsCard'))
const TeaserCard = dynamic(() => import('@/components/TeaserCard'))
const TeamChip = dynamic(() => import('@/components/TeamChip'))
const TextBox = dynamic(() => import('@/components/TextBox'))
const TopPerformerCard = dynamic(() => import('@/components/TopPerformer'))
const TVProgram = dynamic(() => import('@/components/TVProgram'))
const TVProgramExtended = dynamic(() => import('@/components/TVProgram/extended'))
const TVSalesTeaser = dynamic(() => import('@/components/TVSalesTeaser'))
const UpcomingEventStreams = dynamic(() => import('@/components/UpcomingEventStreams'))
const Widget = dynamic(() => import('@/components/Widget'))
const GenericComponent = dynamic(() => import('@/components/Generic'))
const StandingsTeam = dynamic(() => import('@/components/StandingsTeam'))
const TeamSquad = dynamic(() => import('@/components/TeamSquad'))
const TeamSquadInfos = dynamic(() => import('@/components/TeamSquadInfos'))
const TeamTeaserCard = dynamic(() => import('@/components/TeamTeaserCard'))
const Transfers = dynamic(() => import('@/components/Transfers'))
const TeamForm = dynamic(() => import('@/components/TeamForm'))
const Balance = dynamic(() => import('@/components/Balance'))
const NewEventTeaser = dynamic(() => import('@sport1/news-styleguide/NewEventTeaser'))
const Ranking = dynamic(() => import('@/components/Ranking'))

// Some ViewItems have implemented lazy loading logic
export const SELF_LOADING_COMPONENT_TYPES: ComponentType[] = [
    ComponentType.BUNDESLIGA_HUB,
    ComponentType.EDITORIAL_HERO_CARD,
    ComponentType.EXTERNAL_WEB_CONTENT,
    ComponentType.HORIZONTAL_CARD,
    ComponentType.NEWS_FEED_TEASER_CARD,
    ComponentType.PODCAST_SERIES_LIST,
    ComponentType.TEASER_CARD,
    ComponentType.TV_UPCOMING_EVENT_STREAMS,
    ComponentType.WIDGET,
]

// Some ViewItems need a dashboard position
export const DASHBOARD_COMPONENT_TYPES: ComponentType[] = [
    ComponentType.BUNDESLIGA_HUB,
    ComponentType.EDITORIAL_HERO_CARD,
    ComponentType.HORIZONTAL_CARD,
    ComponentType.TEASER_CARD,
    ComponentType.TOP_TEASER_CARD,
    ComponentType.PODCAST_SERIES_LIST,
    ComponentType.TV_UPCOMING_EVENT_STREAMS,
]

const headlineTypeMap: Map<string, string> = new Map([['SPORT1_HIGHLIGHTS', 'SPORT1 HIGHLIGHTS']])

type RenderComponentProps = {
    component: LayoutComponentProps
    layoutType?: 'STANDARD' | 'TV'
    pageTag?: TagProps | LabelProps
    isRenderedByAsyncComponent?: boolean
    rightColumnComponents?: LayoutComponentProps[]
    dashboardPosition?: MutableRefObject<number>
    lazyDashboardPosition?: number
    theme?: BreakPointProps
    index?: number
    pathName?: string
}

const renderBaseChannelComponent = ({
    component,
    layoutType = 'STANDARD',
    pageTag,
    isRenderedByAsyncComponent = false,
    rightColumnComponents,
    dashboardPosition,
    lazyDashboardPosition,
    theme,
    pathName,
}: RenderComponentProps): ReactElement | null => {
    const key =
        component.type && component.componentKey
            ? `render-base-channel-${component.type}_${component.componentKey}`
            : undefined

    if (
        component.lazy &&
        SELF_LOADING_COMPONENT_TYPES.includes(component.type) &&
        component.url &&
        !isRenderedByAsyncComponent
    ) {
        return (
            <AsyncComponent
                key={key}
                type={component.type}
                url={component.url}
                rightColumnComponents={rightColumnComponents}
                dashboardPosition={
                    dashboardPosition && DASHBOARD_COMPONENT_TYPES.includes(component.type)
                        ? dashboardPosition.current++
                        : undefined
                }
            />
        )
    }
    let pageItem = null
    // switch is sorted alphabetical pls look to keep it this way
    switch (component.type) {
        case ComponentType.AD:
            pageItem = (
                <AdSlot
                    key={key}
                    width="100%"
                    marginRight={
                        layoutType === 'TV'
                            ? ['spacing-none', 'spacing-none', 'spacing-10', '160px']
                            : 'spacing-none'
                    }
                    {...component}
                />
            )
            break
        case ComponentType.APESTER:
            pageItem = <Apester key={key} {...component} />
            break
        case ComponentType.AUTHOR_INFO:
            pageItem = <AuthorInfo key={key} component={component} />
            break
        case ComponentType.AUTHOR_OVERVIEW:
            pageItem = <AuthorOverview key={key} {...component} />
            break
        case ComponentType.BUNDESLIGA_HUB:
            const hubDashboardPosition =
                lazyDashboardPosition ||
                (dashboardPosition?.current ? dashboardPosition.current++ : 0)
            pageItem = (
                <BundesligaHub
                    key={key}
                    {...prepareForTeaserTracking({
                        componentType: component.type,
                        component: insertSelectedFilter(component as BundesligaHubProps),
                        dashboardPosition: hubDashboardPosition,
                        getAdditionalTrackingData: () =>
                            getAdditionalTrackingData(component as BundesligaHubProps, theme),
                    })}
                    dashboardPosition={hubDashboardPosition}
                    dashboardPresenting={component?.dashboardPresenting}
                />
            )
            break
        case ComponentType.CONTENT_SHOWCASE:
            pageItem = (
                <ContentShowcase
                    key={key}
                    headline={component.headline}
                    content={component.content}
                />
            )
            break
        case ComponentType.DAZN_PROGRAM_HIGHLIGHTS:
            pageItem = <DaznProgramHighlights key={key} url={component.url} />
            break
        case ComponentType.EPG:
            pageItem = component.extended ? (
                <TVProgramExtended key={key} url={component.epgUrl} />
            ) : (
                <TVProgram key={key} url={component.epgUrl} />
            )
            break
        case ComponentType.EVENT_TEASER_CARD:
            const {
                backgroundImageUrl,
                startTime,
                endTime,
                eventTitle,
                currentRoundTitle,
                sponsorImageUrl,
                competition: { imageUrl },
                winnerTeam,
                sponsorUrl,
                tags,
            } = component
            pageItem = (
                <NonFlexingContainer
                    marginX={['spacing-6', 'spacing-6', 'spacing-9', 'spacing-none']}
                    marginBottom={['spacing-6', 'spacing-6', 'spacing-11']}
                >
                    <NewEventTeaser
                        key={key}
                        backgroundImageUrl={backgroundImageUrl}
                        mainHeadline={eventTitle}
                        championshipImageUrl={
                            eventTitle.toLowerCase() === 'exatlon' ? undefined : imageUrl
                        }
                        startTime={startTime}
                        endTime={endTime}
                        eventTeaserDescriptionText={winnerTeam?.name ?? currentRoundTitle}
                        flagImageUrl={winnerTeam?.imageUrl}
                        spnsorImageUrl={sponsorImageUrl}
                        sponsorOnPress={
                            sponsorUrl ? () => (window.location.href = sponsorUrl) : undefined
                        }
                        eventTeaserOnPress={
                            pathName === '/'
                                ? () => (window.location.pathname = tags?.[0].href || '')
                                : undefined
                        }
                    />
                </NonFlexingContainer>
            )
            break

        case ComponentType.GENERIC:
            pageItem = <GenericComponent key={key} {...component} />
            break
        case ComponentType.HEADLINE:
            pageItem = <Headline key={key} component={component} />
            break
        case ComponentType.NEWS_FEED_ARTICLE:
            pageItem = <NewsFeedArticle key={key} componentData={component} />
            break
        case ComponentType.MATCH_ELEMENTS_CARD:
            const trackingInteractionLabel =
                pageTag?.contextId === '40' ? 'wm_tag-page' : 'liveticker'
            const trackingCreation =
                pageTag?.categoryType === 'TEAM' ? 'inline_spielplan-teammatchdays' : undefined
            pageItem = (
                <MatchElementsCard
                    key={key}
                    {...component}
                    trackingInteractionLabel={trackingInteractionLabel}
                    // TODO: we can not update bet-service-client because of sport1-components, maybe using S1_WIDGETS like in the App?
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    trackingCreation={trackingCreation} // TODO: make generic if Gameplan will be implemented
                />
            )
            break
        case ComponentType.NEWS_FEED_TEASER_CARD:
            if (component.content && component.content.length) {
                pageItem = (
                    <NewsFeedCard
                        key={key}
                        content={component.content}
                        actionButtons={component.actionButtons}
                        tags={component.tags}
                    />
                )
            }
            break
        case ComponentType.OPTA_WIDGET:
            pageItem = <OptaWidget key={key} {...component} />
            break
        case ComponentType.PAY_TV_PLAYER:
            pageItem = (
                <NonFlexingContainer
                    key={key}
                    marginRight={['spacing-none', 'spacing-none', 'spacing-10', '160px']}
                >
                    <Inplayer key={key} id={component.id} brandingId={component.brandingId} />
                </NonFlexingContainer>
            )
            break
        case ComponentType.PIANO_PASSWORD_RESET:
            pageItem = <PianoPasswordReset key={key} />
            break
        case ComponentType.PODCAST_SERIES_LIST:
            if (component.content && component.content.length) {
                if (!lazyDashboardPosition && dashboardPosition) {
                    dashboardPosition.current++
                }
                pageItem = <PodcastSeriesList key={key} componentData={component} />
            }
            break
        case ComponentType.RESULT_TEASER:
            if (component.content?.length) {
                pageItem = (
                    <OddsDisclaimerContextProvider key={key}>
                        <ResultTeaser
                            trackingInteractionLabel="result-teaser"
                            componentData={component}
                            key={key}
                        />
                    </OddsDisclaimerContextProvider>
                )
            }
            break
        case ComponentType.STANDINGS:
            if (component.selectorUrls?.SEASON) {
                pageItem = (
                    <CompetitionStandings
                        seasons={component.seasons}
                        initialTeamStandings={component.teamStandings}
                        teamStandingsUrl={component.contentUrls?.TEAM_STANDINGS}
                        componentKey={component.componentKey}
                    ></CompetitionStandings>
                )
            } else if (
                component.contentUrls?.TEAM_STANDINGS &&
                component.selectorUrls?.COMPETITION
            ) {
                pageItem = (
                    <StandingsTeam
                        key={key}
                        componentKey={component.componentKey}
                        contentUrl={component.contentUrls.TEAM_STANDINGS}
                        competitionUrl={component.selectorUrls.COMPETITION}
                    />
                )
            } else {
                pageItem = null
            }
            break
        case ComponentType.STANDINGS_CARD:
            pageItem = (
                <StandingsCard
                    key={key}
                    {...component}
                    paddingX={
                        pageTag?.contextId === '40'
                            ? ['spacing-7', 'spacing-9', 'spacing-10']
                            : 'spacing-none'
                    } //contextId 40 := World Cup
                    paddingY={
                        pageTag?.contextId === '40'
                            ? ['spacing-7', 'spacing-9', 'spacing-10']
                            : 'spacing-none'
                    }
                />
            )
            break
        case ComponentType.TRANSFERS:
            pageItem = <Transfers key={key} {...component} />
            break
        case ComponentType.TEASER_CARD:
        case ComponentType.HORIZONTAL_CARD:
            //Here is a dashboard
            if (component.content && component.content.length > 0) {
                const componentTag = getTag(component.tags)
                const hideHeadline =
                    componentTag?.contextId && componentTag.contextId === pageTag?.contextId
                const isHorizontal = component.type === ComponentType.HORIZONTAL_CARD

                const teaserCardDashboardPosition =
                    lazyDashboardPosition ||
                    (dashboardPosition?.current ? dashboardPosition.current++ : 0)
                const componentTracking = prepareForTeaserTracking({
                    componentType: component.type,
                    component,
                    dashboardPosition: teaserCardDashboardPosition,
                })
                const title = !hideHeadline
                    ? (componentTracking.headlineType &&
                          headlineTypeMap.get(componentTracking.headlineType)) ||
                      componentTag?.title
                    : undefined

                pageItem = (
                    <TeaserCard
                        key={key}
                        teaser={prepareTeasers(componentTracking)}
                        title={title}
                        actionButtons={componentTracking.actionButtons}
                        rightColumnComponents={rightColumnComponents}
                        tag={componentTag}
                        horizontal={isHorizontal}
                        testID="teaser-card"
                        dashboardPresenting={component?.dashboardPresenting}
                        lazy={componentTracking.lazy}
                        horizontalScrollbarEnabled={pageTag?.contextId === '40'}
                        paddingX={
                            pageTag?.contextId === '40'
                                ? ['spacing-7', 'spacing-9', 'spacing-10']
                                : 'spacing-none'
                        } //contextId 40 := World Cup
                        paddingBottom="spacing-11"
                        spaceX={pageTag?.contextId === '40' ? 'none' : undefined}
                    />
                )
            }
            break
        case ComponentType.TEAM_CHIP:
            pageItem = <TeamChip key={key} {...component} />
            break
        case ComponentType.TEAM_FORM_CARD:
            pageItem = <TeamForm key={key} {...component} />
            break
        case ComponentType.TEAM_MATCH_SEASON_STATISTICS:
            pageItem = <Balance key={key} {...component} />
            break
        case ComponentType.TEAM_SQUAD:
            pageItem = <TeamSquad key={key} {...component} />
            break
        case ComponentType.TEAM_SQUAD_INFO:
            pageItem = <TeamSquadInfos key={key} isRightColumn {...component} />
            break
        case ComponentType.TEAM_VIDEO_CARD:
        case ComponentType.TEAM_TEASER_CARD:
            if (component.content && component.content.length > 0) {
                const teaserCardDashboardPosition =
                    lazyDashboardPosition ||
                    (dashboardPosition?.current ? dashboardPosition.current++ : 0)
                const componentTracking = prepareForTeaserTracking({
                    componentType: component.type,
                    component,
                    dashboardPosition: teaserCardDashboardPosition,
                })
                pageItem = (
                    <TeamTeaserCard
                        type={component.type}
                        key={key}
                        teaser={
                            componentTracking.content?.slice(
                                0,
                                Math.min(
                                    componentTracking.numberOfContentsToShow ||
                                        TEASER_CARD_MAX_ITEMS,
                                    TEASER_CARD_MAX_ITEMS
                                )
                            ) || []
                        }
                        title={component.headline}
                        actionButtons={componentTracking.actionButtons}
                        rightColumnComponents={rightColumnComponents}
                        tag={getTag(component.tags)}
                        marginBottom="spacing-9"
                        testID="teaser-card"
                        dashboardPresenting={component?.dashboardPresenting}
                        lazy={componentTracking.lazy}
                        horizontalScrollbarEnabled={pageTag?.contextId === '40'}
                        paddingX={
                            pageTag?.contextId === '40'
                                ? ['spacing-7', 'spacing-9', 'spacing-10']
                                : 'spacing-none'
                        } //contextId 40 := World Cup
                        paddingBottom="spacing-11"
                        spaceX={pageTag?.contextId === '40' ? 'none' : undefined}
                    />
                )
            }
            break
        case ComponentType.TEXTBOX:
            pageItem = <TextBox key={key} layoutTextBox={component} />
            break
        case ComponentType.TOP_PERFORMER_CARD:
            pageItem = <TopPerformerCard key={key} {...component} />
            break
        case ComponentType.TOP_TEASER_CARD:
        case ComponentType.EDITORIAL_HERO_CARD:
            //Here is a dashboard
            if (component.content && component.content.length > 0) {
                const topTeaserCardDashboardPosition =
                    lazyDashboardPosition ||
                    (dashboardPosition?.current ? dashboardPosition.current++ : 0)
                const componentTracking = prepareForTeaserTracking({
                    componentType: component.type,
                    component,
                    dashboardPosition: topTeaserCardDashboardPosition,
                })

                pageItem = (
                    <TeaserCard
                        key={key}
                        teaser={componentTracking.content.slice(
                            0,
                            Math.min(
                                componentTracking.numberOfContentsToShow || TEASER_CARD_MAX_ITEMS,
                                TEASER_CARD_MAX_ITEMS
                            )
                        )}
                        rightColumnComponents={rightColumnComponents}
                        testID="top-teaser-card"
                        lazy={componentTracking.lazy}
                        horizontalScrollbarEnabled={false}
                    />
                )
            }
            break
        case ComponentType.TV_UPCOMING_EVENT_STREAMS:
            if (component.content?.length > 0) {
                const topTeaserCardDashboardPosition =
                    lazyDashboardPosition ||
                    (dashboardPosition?.current ? dashboardPosition.current++ : 0)
                const cardHeaderProps: EventCardHeadlineProps = {
                    hint: component.hint,
                    title: component.title,
                    link: component.link,
                    iconUrl: component.iconUrl,
                }
                // ToDo: remove this once the value is provided by the BE
                if (
                    component.meta?.tracking &&
                    !component.meta.tracking.some(item => item.key === 'variant')
                ) {
                    component.meta.tracking.push({
                        key: 'variant',
                        value: component.title,
                    })
                }
                pageItem = (
                    <UpcomingEventStreams
                        key={key}
                        cardHeaderProps={cardHeaderProps}
                        eventStreamTeaser={
                            prepareForTeaserTracking({
                                componentType: component.type,
                                component,
                                dashboardPosition: topTeaserCardDashboardPosition,
                            }).content
                        }
                        layoutType={layoutType}
                    />
                )
            }
            break
        case ComponentType.TV_SALES_TEASER:
            pageItem = <TVSalesTeaser key={key} teaser={component.content} />
            break
        case ComponentType.WIDGET:
            if (!component.widgetType.includes(disabledWidgets.toString())) {
                pageItem = <Widget key={key} widget={component} />
            }
            break
        case ComponentType.RANKING:
            pageItem = <Ranking key={key} pathName={pathName} component={component} />
            break
    }
    return pageItem
}

export default renderBaseChannelComponent
